<template>
  <div>
  </div>
</template>


<script>
export default {

  name: 'ActivateEmailContent',

  data(){
    return {
      newPassword: '',
      newPassword2: '',
    }
  },

  mounted(){

    this.verifyCode()
  },

  methods: {

    verifyCode(){

      this.appWorking();
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'activate-email-change',
        data: {
          code: this.$route.params.code,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {
      
          this.appSuccess('Ihre E-Mail-Adresse wurde erfolgreich aktiviert. Zukünftig erhalten Sie Einladungen zu Umfragen von forsa.omninet an Ihre neue E-Mail-Adresse.');

          this.$router.push({name: 'portal'});
        }
      )
      .fail(
        resp => {
      
          this.appError('Bei der Aktivierung der neuen E-Mail-Adresse ist ein Fehler ist aufgetreten. Der Aktivierungslink ist ungültig.', null);

          this.$router.push({name: 'portal'});
        }
      );
    },
  },
}
</script>
