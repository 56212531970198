<template>
  <div id="activateEmail">

    <HorizontalContainer>

      <ActivateEmailContent />

      <FullscreenSpinner />

    </HorizontalContainer>

    <Footer />

  </div>
</template>


<script>
import HorizontalContainer from '@/components/wrapper/HorizontalContainer'

import ActivateEmailContent from '@/components/sections/TheActivateEmailContent'
import Footer from '@/components/sections/TheFooter'

export default {

  name: 'ActivateEmail',

  components: {
    HorizontalContainer,
    ActivateEmailContent,
    Footer,
  },
}
</script>


<style scoped>
#activateEmail {
  display: flex;
  flex-direction: column;
}
#activateEmail > .hContainer {
  flex-grow: 1;
  padding: 48px 12px 24px 12px;
}
</style>